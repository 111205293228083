import { handleGqlResponseBody } from "@/utils/apollo.util"
import { GET_PROPERTY_ROOMS_PAGINATION_QUERY } from "@/graphql/queries/property/get-property-rooms-pagination.query"
import { GET_PROPERTY_ROOM_QUERY } from "@/graphql/queries/property/get-property-room.query"
import {
  UPDATE_PROPERTY_ROOM_REPRESENTATIVE_USER_MUTATION
} from "@/graphql/mutations/update-property-room-representative-user.mutation"
import {
  CREATE_PROPERTY_ROOM_REPRESENTATIVE_USER_MUTATION
} from "@/graphql/mutations/create-property-room-representative-user.mutation"
import { VACATE_PROPERTY_ROOM_MUTATION } from "@/graphql/mutations/vacate-property-room.mutation"
import { GET_FAMILY_MEMBER_IMAGES } from "@/graphql/queries/property/get-family-member-images"

const propertyRoom = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getPropertyRoomQuery ({ commit }, { id }) {
      return handleGqlResponseBody(apollo => {
        return apollo.query({
          query: GET_PROPERTY_ROOM_QUERY,
          variables: { id },
          fetchPolicy: "no-cache"
        })
      })
    },
    async getPropertyRoomsPaginationQuery ({ commit }, {
      skip, take, orderBy, propertyFloorId, number, representativeFullName, representativeFullNameKana, representativeTelNumber
    }) {
      return handleGqlResponseBody(apollo => {
        return apollo.query({
          query: GET_PROPERTY_ROOMS_PAGINATION_QUERY,
          variables: {
            skip, take, orderBy, propertyFloorId, number, representativeFullName, representativeFullNameKana, representativeTelNumber
          },
          fetchPolicy: "no-cache"
        })
      })
    },
    async createPropertyRoomRepresentativeUser ({ commit }, {
      propertyRoomId, firstName, lastName, firstNameKana, lastNameKana, email, telNumber, isActive, icCardIds
    }) {
      return handleGqlResponseBody(apollo => {
        return apollo.mutate({
          mutation: CREATE_PROPERTY_ROOM_REPRESENTATIVE_USER_MUTATION,
          variables: {
            propertyRoomId, firstName, lastName, firstNameKana, lastNameKana, email, telNumber, isActive, icCardIds
          },
          fetchPolicy: 'no-cache'
        })
      })
    },
    async updatePropertyRoomRepresentativeUser ({ commit }, {
      userId, firstName, lastName, firstNameKana, lastNameKana, email, telNumber, isActive, icCardIds, familyMembers
    }) {
      return handleGqlResponseBody(apollo => {
        return apollo.mutate({
          mutation: UPDATE_PROPERTY_ROOM_REPRESENTATIVE_USER_MUTATION,
          variables: {
            userId, firstName, lastName, firstNameKana, lastNameKana, email, telNumber, isActive, icCardIds, familyMembers
          },
          fetchPolicy: 'no-cache'
        })
      })
    },
    async vacatePropertyRoom (_, {
      propertyRoomId,
      defaultPassword,
      roomNumber
    }) {
      return handleGqlResponseBody(apollo => {
        return apollo.mutate({
          mutation: VACATE_PROPERTY_ROOM_MUTATION,
          variables: {
            propertyRoomId,
            defaultPassword,
            roomNumber
          }
        })
      })
    },

    async getFamilyMemberImages (_ctx, { familyMemberId }) {
      return handleGqlResponseBody(apollo => {
        return apollo.query({
          query: GET_FAMILY_MEMBER_IMAGES,
          variables: { id: familyMemberId },
          fetchPolicy: 'no-cache'
        })
      })
    }
  }
}

export default propertyRoom
